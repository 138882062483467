body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}
.App {
  text-align: center;
  
}
.Home{
  background-color: #282c34;
}
.Home-logo{
  width: calc(40%);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.menu-mobile{
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
}
.dot{
  width: 20px;
  height: 4px;
  display: block;
  background: white;
}
.App-header::before{
  width: 100%;
  background-color: #282c34;
}
.App-header {
  background-color: #282c34;
  height: 10vh;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
  margin: auto;
}
.App-header .App-header-logo{
  width: 40%;
  text-align: left;
}
.App-header-logo>img{
  width: 40%;
  object-fit: cover;
  text-align: left;
}
.App-header>span:nth-child(2){
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.App-link {
  color: inherit;
  text-decoration: none;
}
.App-link:hover{
  color:#928159;
}
.App-Container{
  height: 90vh;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.App-Container>p{
  width: 60%;
  color: #928159;
  font-size: 2vw;
  letter-spacing: 0.3px;
}
.App-footer{
  position: absolute;
  bottom: 10px;
  color:white;
  width: 100%;
  text-align: center;
  font-size: 1.5vw;
}

.App-Container-Cover{
  background-image: url(/static/media/bg_img.e70347e6.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20vh;
  width: 100%;
  height: 90vh;
  position: absolute;
  z-index: -1;
}
